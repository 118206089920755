const toggleFooterMenu = function () {
  let footerMenu = document.querySelector('[data-mobile-menu="footer"]');
  let overlay = document.querySelector('.overlay');

  if (!footerMenu) {
    return;
  }

  const resizeHandler = () => {
    if (window.matchMedia('(min-width: 768px)').matches) {
      enableBodyScroll(footerMenu);
    } else if (footerMenu.classList.contains('mobile-menu--open')) {
      disableBodyScroll(footerMenu);
    }
  };

  const openMenu = () => {
    footerMenu.classList.add('mobile-menu--open');
    disableBodyScroll(footerMenu);
    overlay.classList.add('overlay--show');
    window.addEventListener('resize', resizeHandler);
  };

  const closeMenu = () => {
    footerMenu.classList.remove('mobile-menu--open');
    enableBodyScroll(footerMenu);
    overlay.classList.remove('overlay--show');
    window.removeEventListener('resize', resizeHandler);
  };

  document.addEventListener('click', function (evt) {
    if (evt.target.closest('[data-footer-nav-open]')) {
      openMenu();
    }

    if (evt.target.closest('[data-footer-nav-close], .overlay')) {
      closeMenu();
    }
  });

  document.addEventListener('keydown', function (evt) {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      if (overlay.classList.contains('overlay--show') === true) {
        closeMenu();
      }
    }
  });
};

export {toggleFooterMenu};
