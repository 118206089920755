export function setServiceTimer() {
  const timer = document.querySelector('.timer');
  const hours = document.querySelectorAll('.timer__box--h');
  const minutes = document.querySelectorAll('.timer__box--m');
  const seconds = document.querySelectorAll('.timer__box--s');

  function parseDuration(duration) {
    let remain = duration;

    const h = Math.floor(remain / (1000 * 60 * 60));
    remain = remain % (1000 * 60 * 60);

    const m = Math.floor(remain / (1000 * 60));
    remain = remain % (1000 * 60);

    const s = Math.floor(remain / (1000));

    return {
      h,
      m,
      s,
    };
  }

  function getData() {
    let date = new Date(timer.dataset.date).getTime();
    const now = new Date().getTime();
    if (typeof date !== 'number' || Number.isNaN(date) || date < now) {
      date = new Date().setHours(new Date().getHours() + 2);
    }
    const diff = (date - now);
    return parseDuration(diff);
  }

  function test(domElements, values) {
    const diff = domElements.length - values.length;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        values.unshift('0');
      }
    }
  }

  function setData(domElements, values, doTest = true) {
    const valuesArray = [...String(values)];
    if (doTest) {
      test(domElements, valuesArray);
    }
    domElements.forEach((element, i) => {
      element.textContent = valuesArray[i];
    });
  }

  function startSecTimer(sec) {
    const secInterval = setInterval(() => {
      sec--;
      setData(seconds, sec);
      if (sec === -1) {
        clearInterval(secInterval);
        checkMinutes(leftMinutes);
      }
    }, 1000);
  }

  function checkMinutes(leftMinutes) {
    if (leftMinutes > 0) {
      --leftMinutes;
      setData(minutes, leftMinutes);
      setData(seconds, 59);
      startSecTimer(59);
      return;
    }
    checkHours();
  }

  function checkHours() {
    if (leftHours > 0) {
      --leftHours;
      leftMinutes = 59;
      setData(minutes, 59);
      setData(seconds, 59);
      startSecTimer(59);
      setData(hours, leftHours);
      return;
    }
    stopTimer();
  }

  function startTimer() {
    let sec = data.s;
    startSecTimer(sec);
  }

  function stopTimer() {
    setData(hours, 0);
    setData(minutes, 0);
    setData(seconds, 0);
  }

  if (!timer) {
    return;
  }
  const data = getData();
  setData(hours, data.h);
  setData(minutes, data.m);
  setData(seconds, data.s);
  let leftMinutes = data.m;
  let leftHours = data.h;
  startTimer();
}
