function changeCardShape() {
  const btnTiles = document.querySelector('#btn-tiles');
  const btnList = document.querySelector('#btn-list');
  const items = document.querySelectorAll('.knowledge-base__item');
  const list = document.querySelector('.knowledge-base__list');
  const ACTIVE_CLASS_NAME = 'knowledge-base__toggle--active';
  const LINES_CLASS_NAME = 'knowledge-base__list--lines';
  const CLASS_LIST_NAME = 'knowledge-base__item--list';
  const CLASS_CARD_LIST_NAME = 'knowledge-card--list';
  let activeShape = btnTiles;

  function onChangeShape(evt) {
    evt.preventDefault();
    if (evt.currentTarget.classList.contains(ACTIVE_CLASS_NAME)) {
      return;
    }
    activeShape.classList.remove(ACTIVE_CLASS_NAME);
    activeShape = activeShape === btnTiles ? btnList : btnTiles;
    activeShape.classList.add(ACTIVE_CLASS_NAME);
    list.classList.toggle(LINES_CLASS_NAME);
    items.forEach((item) => {
      item.classList.toggle(CLASS_LIST_NAME);
      item.firstElementChild.classList.toggle(CLASS_CARD_LIST_NAME);
    });
  }

  if (btnTiles && btnList) {
    btnTiles.addEventListener('click', onChangeShape);
    btnList.addEventListener('click', onChangeShape);
  }
}


export {changeCardShape};
