function manage2faModal() {
  const modal = document.querySelector('.modal--2fa');

  if (modal) {
    const codeInput = modal.querySelector('#confirmation-code');
    const submitButton = modal.querySelector('.modal__submit');

    if (codeInput && submitButton) {
      codeInput.addEventListener('input', () => {
        submitButton.disabled = codeInput.value === '';
      });
    }
  }
}

export {manage2faModal};
