/* globals Swiper */
export function enableIntSlider() {
  const shade = document.querySelector('.interesting__shade');
  if (!shade) {
    return;
  }
  const swiper = new Swiper('.interesting__slider', {
    slidesPerView: 4,
    spaceBetween: 20,
    navigation: {
      nextEl: '.interesting__btn--next',
      prevEl: '.interesting__btn--prev',
    },
    a11y: {
      prevSlideMessage: 'Предыдущий слайд',
      nextSlideMessage: 'Следующий слайд',
      firstSlideMessage: 'Первый слайд',
      lastSlideMessage: 'Последний слайд',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      },
    },
  });

  function checkShade() {
    if (swiper.isBeginning) {
      shade.classList.remove('interesting__shade--left');
    } else if (swiper.isEnd) {
      shade.classList.remove('interesting__shade--right');
    } else {
      shade.classList.add('interesting__shade--left');
      shade.classList.add('interesting__shade--right');
    }
  }

  swiper.on('activeIndexChange', checkShade);
}
