export const exchange = () => {
  const formSelectElements = document.querySelectorAll('.form-select');


  if (!formSelectElements.length) {
    return;
  }


  formSelectElements.forEach((selectElement) => {
    const dropdown = selectElement.querySelector('.dropdown');
    const submenu = selectElement.querySelector('.menu .menu');

    window.$(dropdown).dropdown({
      forceSelection: false,
      direction: 'downward',

      onShow: () => {
        window.dispatchEvent(new CustomEvent('scrollbars-refresh-request', {
          detail: {
            delay: 200,
          },
        }));
      },

      onHide: () => {
        window.dispatchEvent(new Event('currency-list-close-request'));
      },
    });

    window.$(dropdown).dropdown('set selected', submenu ? submenu.querySelector('.item').dataset.value : dropdown.querySelector('.item').dataset.value);
  });


  document.addEventListener('click', (evt) => {
    const formSelectItem = evt.target.closest('.form-select .item');

    if (formSelectItem) {
      const expandedCurrencyList = formSelectItem.closest('.form-select').querySelector('.exchange-currency.is-expanded');

      window.dispatchEvent(new CustomEvent('scrollbars-refresh-request', {
        detail: {
          delay: 200,
        },
      }));

      if (expandedCurrencyList && !formSelectItem.querySelector('.exchange-currency.is-expanded')) {
        window.dispatchEvent(new Event('exchange-currency-close-request'));
      }
    }
  });
};
