export function togglePassword() {
  const showPasswordButtons = document.querySelectorAll('[data-show-password]');

  if (!showPasswordButtons.length) {
    return;
  }

  function togglePasswordState(evt) {
    evt.preventDefault();
    const currentState = evt.currentTarget.dataset.showPassword;
    switch (currentState) {
      case 'isShowing':
        evt.currentTarget.dataset.showPassword = 'isHidden';
        evt.currentTarget.ariaLabel = 'Показать пароль';
        evt.currentTarget.parentElement.querySelector('input').type = 'password';
        evt.currentTarget.firstElementChild.src = './img/sprite/icon-eye-open.svg';
        break;
      case 'isHidden':
        evt.currentTarget.dataset.showPassword = 'isShowing';
        evt.currentTarget.ariaLabel = 'Скрыть пароль';
        evt.currentTarget.parentElement.querySelector('input').type = 'text';
        evt.currentTarget.firstElementChild.src = './img/sprite/icon-eye-close.svg';
        break;
    }
  }

  showPasswordButtons.forEach((btn) => {
    btn.addEventListener('click', togglePasswordState);
  });
}
