const setRating = function () {
  let submitRating = document.querySelector('.rating__form-wrapper form');
  let ratingBlock = document.querySelector('.rating');
  let ratingStars = document.querySelectorAll('.rating__stars input[type="radio"]');
  let ratingTitle = document.querySelector('.rating .title--h2');
  let ratingText = document.querySelector('.rating .rating__text');

  if (ratingBlock) {

    for (let i = 0; i < ratingStars.length; i++) {
      ratingStars[i].addEventListener('click', function (event) {
        ratingStars.forEach(function (star) {
          star.removeAttribute('checked');
        });
        event.target.setAttribute('checked', '');
      });
    }


    submitRating.addEventListener('submit', function (evt) {
      let ratingValue = document.querySelector('.rating__stars input[checked]').value;
      ratingTitle.textContent = ratingTitle.dataset.replaceTitle;
      evt.preventDefault();

      if (ratingValue >= 7) {
        ratingBlock.classList.add('rating--high-rate');
        ratingText.textContent = ratingText.dataset.highRateText;
      }

      if (ratingValue <= 6) {
        ratingBlock.classList.add('rating--low-rate');
        ratingText.textContent = ratingText.dataset.lowRateText;
      }
    });
  }
};

export {setRating};
