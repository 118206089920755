/* globals Swiper */
export function enableNewsSlider() {
  const slider = document.querySelector('.news-page__news-slider');
  let isInit = false;
  let swiper;

  function checkNewsSlider() {
    if (document.documentElement.clientWidth < 768 && !isInit) {
      swiper = new Swiper(slider, {
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          450: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
        },
        navigation: {
          nextEl: '.news-page__news-btn--next',
          prevEl: '.news-page__news-btn--prev',
        },
        a11y: {
          prevSlideMessage: 'Предыдущий слайд',
          nextSlideMessage: 'Следующий слайд',
          firstSlideMessage: 'Первый слайд',
          lastSlideMessage: 'Последний слайд',
        },
      });
      isInit = true;
    } else if (document.documentElement.clientWidth > 768 && isInit) {
      swiper.destroy();
      isInit = false;
    }
  }

  if (slider) {
    checkNewsSlider();
    window.addEventListener('resize', checkNewsSlider);
  }
}
