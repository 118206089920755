/* globals PerfectScrollbar */
const initScrollbar = function () {
  const MIN_DESKTOP_WIDTH = 1280;
  const selectorsWithScrollbarList = [
    '[data-custom-scrollbar]',
    '.selectr-options',
    '#datalist-ul',
    '#getting-datalist-ul',
    '#getting-cardholder-city-ul',
    '#datalist-ul-cardholder-telephone',
    '#datalist-ul-telephone',
    '.accordion',
    '.form-select .ui.dropdown .menu .menu',
    '.form-select .ui.dropdown .menu.no-submenu',
    '.payment-systems__systems .ui.dropdown .menu'
  ];

  const selectorsWithNoDesktopScrollbars = [
    '.payment-systems__categories .ui.dropdown .menu'
  ];

  const initScrollbarsFromSelectors = (selectors) => {
    const scrollbarsList = [];

    selectors.forEach((selector) => {
      const elements = document.querySelectorAll(selector);

      elements.forEach((element) => {
        const scrollbar = new PerfectScrollbar(element, {
          wheelSpeed: 0.2,
          wheelPropagation: false,
          swipePropagation: false,
          maxScrollbarLength: 28,
          suppressScrollX: true,
        });

        // scrollbar.scrollbarY.style.height = '28px';

        scrollbarsList.push(scrollbar);
      });
    });

    return scrollbarsList;
  };

  let scrollbarsAll = [];
  let scrollbarsMobile = [];

  scrollbarsAll = initScrollbarsFromSelectors(selectorsWithScrollbarList);

  if (window.matchMedia(`(max-width: ${MIN_DESKTOP_WIDTH - 1}px)`).matches) {
    scrollbarsMobile = initScrollbarsFromSelectors(selectorsWithNoDesktopScrollbars);
  }

  window.addEventListener('load', function () {
    scrollbarsAll.forEach((scrollbar) => {
      scrollbar.update();
    });

    scrollbarsMobile.forEach((scrollbar) => {
      scrollbar.update();
    });
  });

  window.addEventListener('resize', function () {
    scrollbarsAll.forEach((scrollbar) => {
      scrollbar.update();
    });

    if (scrollbarsMobile.length && window.matchMedia(`(min-width: ${MIN_DESKTOP_WIDTH}px)`).matches) {
      scrollbarsMobile.forEach((scrollbar) => {
        scrollbar.destroy();
      });
      scrollbarsMobile = [];
    }

    if (!scrollbarsMobile.length && window.matchMedia(`(max-width: ${MIN_DESKTOP_WIDTH - 1}px)`).matches) {
      scrollbarsMobile = initScrollbarsFromSelectors(selectorsWithNoDesktopScrollbars);
    }
  });

  window.addEventListener('selectr-open', () => {
    setTimeout(() => {
      scrollbarsAll.forEach((scrollbar) => {
        scrollbar.update();
      });
    }, 20);

  });

  window.addEventListener('scrollbars-refresh-request', (evt) => {
    const delay = evt.detail ? evt.detail.delay : 50;

    setTimeout(() => {
      scrollbarsAll.forEach((scrollbar) => {
        scrollbar.update();
      });
      scrollbarsMobile.forEach((scrollbar) => {
        scrollbar.update();
      });
    }, delay);

    console.log('refresh scrollbars delay ' + delay);
    console.log(evt);
  });
};

export {initScrollbar};
