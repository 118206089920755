import {modalCodesCheck} from './modalCodesCheck';

export function checkModalCodeAuth() {
  const modalCodesAuth = document.querySelector('.modal--codes-auth');
  if (!modalCodesAuth) {
    return;
  }

  const inputEmail = document.querySelector('#modal-codes-auth-email');
  const inputSMS = document.querySelector('#modal-codes-auth-sms');
  const time = document.querySelector('#modal-codes-auth-time');
  const messageLink = document.querySelector('#modal-codes-auth-message-link');

  modalCodesCheck(inputEmail, inputSMS, time, messageLink);
}
