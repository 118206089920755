const changeExchangeForm = function () {
  let exchangeForm = document.querySelector('.exchange-form');
  let exchangeFormWrapper = document.querySelector('.exchange-form__wrapper');
  let protectionCheckbox = document.querySelector('.getting__protection input[type="checkbox"]');
  let gettingAccountInput = document.getElementById('getting-account-input');
  let gettingAccountList = document.getElementById('getting-account-list');

  if (exchangeForm) {

    if (protectionCheckbox) {
      protectionCheckbox.addEventListener('change', () => {
        exchangeFormWrapper.classList.toggle('exchange-form__wrapper--protected');
      });
    }

    if (gettingAccountInput) {
      gettingAccountInput.addEventListener('input', () => {
        if (gettingAccountInput.value.length === 19) {
          if (exchangeFormWrapper.classList.contains('exchange-form__wrapper--protected')) {
            exchangeFormWrapper.classList.remove('exchange-form__wrapper--protected');
          }
          protectionCheckbox.checked = false;
          exchangeFormWrapper.classList.toggle('exchange-form__wrapper--extended');
        }
      });
    }

    if (gettingAccountList) {
      gettingAccountList.addEventListener('click', function (e) {
        if (e.target.closest('.item')) {
          gettingAccountInput.value = e.target.innerText;
          if (gettingAccountInput.value.length === 19) {
            exchangeFormWrapper.classList.remove('exchange-form__wrapper--protected');
            protectionCheckbox.checked = false;
            exchangeFormWrapper.classList.toggle('exchange-form__wrapper--extended');
          }
        }
      });
    }
  }
};

export {changeExchangeForm};
