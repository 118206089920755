const questionsAnswers = function () {
  let items = document.querySelectorAll('.accordion__item');
  let headings = document.querySelectorAll('.accordion__heading');
  let faqList = document.querySelector('.accordion');
  let KEY_ENTER = 'Enter';

  if (faqList) {
    if (faqList.classList.contains('accordion-no-js')) {
      faqList.classList.remove('accordion-no-js');
    }
  }

  let onOpenCloseAnswer = function (item) {
    if (item.classList.contains('accordion__item--active-question')) {
      item.classList.remove('accordion__item--active-question');
    } else {
      if (item.classList.contains('accordion__item--active-question')) {
        item.classList.remove('accordion__item--active-question');
      } else {
        item.classList.add('accordion__item--active-question');
      }
    }
  };

  let openCloseAnswer = function (heading, item) {

    heading.addEventListener('click', function () {
      onOpenCloseAnswer(item);
    });

    item.addEventListener('keydown', function (evt) {
      if (evt.key === KEY_ENTER) {
        evt.preventDefault();
        onOpenCloseAnswer(item);
      }
    });
  };

  if (items) {
    for (let i = 0; i < items.length; i++) {
      openCloseAnswer(headings[i], items[i]);
    }
  }
};

export {questionsAnswers};
