export const initPaymentSystems = () => {
  const DESKTOP_WIDTH = 1280;

  const paymentSystemsElements = document.querySelectorAll('.payment-systems');

  if (!paymentSystemsElements) {
    return;
  }

  const paymentSelectElements = document.querySelectorAll('.payment-systems .dropdown');


  let sliderInstances = [];


  const initSliders = () => {
    paymentSystemsElements.forEach((paymentSystemElement) => {
      const sliderContainerElement = paymentSystemElement.querySelector('.swiper-container');

      const navigation = {
        prevEl: paymentSystemElement.querySelector('.payment-systems__nav-button--left'),
        nextEl: paymentSystemElement.querySelector('.payment-systems__nav-button--right'),
      };

      sliderInstances.push(new window.Swiper(sliderContainerElement, {
        slidesPerView: 'auto',
        resistanceRatio: 0,
        spaceBetween: 48,

        navigation,
      }));
    });
  };

  const destroySliders = () => {
    sliderInstances.forEach((instance) => {
      instance.destroy();
    });

    sliderInstances = [];
  };


  if (window.innerWidth >= DESKTOP_WIDTH) {
    initSliders();
  }


  paymentSelectElements.forEach((selectElement) => {
    window.$(selectElement).dropdown({
      forceSelection: false,
      direction: 'downward',
    });

    if (selectElement.closest('.payment-systems__categories')) {
      window.$(selectElement).dropdown('set selected', selectElement.querySelector('.item').dataset.value);
    }

    if (selectElement.closest('.payment-systems__systems')) {
      const firstItem = selectElement.querySelector('.item');

      if (firstItem) {
        const id = firstItem.closest('.dropdown').querySelector('input[type="hidden"]').name;
        const outputInput = document.querySelector(`.exchange__output input[name="${ id }"]`);

        if (outputInput) {
          const wrapper = outputInput.closest('.exchange__output');
          const currentSystem = wrapper.querySelector('.payment-system');

          outputInput.value = firstItem.dataset.value;

          if (currentSystem) {
            currentSystem.remove();
          }

          wrapper.append(firstItem.querySelector('.payment-system').cloneNode(true));
        }
      }

      window.$(selectElement).dropdown('set selected', selectElement.querySelector('.item').dataset.value);
    }
  });


  document.addEventListener('click', (evt) => {
    const categoriesItem = evt.target.closest('.payment-systems__categories .item');
    const systemsItem = evt.target.closest('.payment-systems__systems .item');

    if (categoriesItem) {
      const parentBlock = categoriesItem.closest('.payment-systems');
      const systems = parentBlock.querySelectorAll('.payment-systems__systems .item');

      const targetCategory = categoriesItem.dataset.value;

      systems.forEach((system) => {
        system.classList.toggle('is-hidden', targetCategory !== 'all' ? system.dataset.category.indexOf(targetCategory) === -1 : false);
      });
    }

    if (systemsItem) {
      const id = systemsItem.closest('.dropdown').querySelector('input[type="hidden"]').name;
      const outputInput = document.querySelector(`.exchange__output input[name="${ id }"]`);

      if (outputInput) {
        const wrapper = outputInput.closest('.exchange__output');
        const currentSystem = wrapper.querySelector('.payment-system');

        outputInput.value = systemsItem.dataset.value;

        if (currentSystem) {
          currentSystem.remove();
        }

        wrapper.append(systemsItem.querySelector('.payment-system').cloneNode(true));
      }
    }


    const formSelectItem = evt.target.closest('.payment-systems .item');

    if (formSelectItem) {
      const expandedCurrencyList = formSelectItem.closest('.dropdown').querySelector('.exchange-currency.is-expanded');

      if (expandedCurrencyList && !formSelectItem.querySelector('.exchange-currency.is-expanded')) {
        window.dispatchEvent(new Event('exchange-currency-close-request'));
      }
    }
  });


  window.addEventListener('resize', () => {
    if (window.innerWidth >= DESKTOP_WIDTH && !sliderInstances.length) {
      initSliders();
    }

    if (window.innerWidth < DESKTOP_WIDTH && sliderInstances.length) {
      destroySliders();
    }
  });
};
