function showSubmitResponse(form) {
  const RESPONSE_HIDE_TIMEOUT = 3000; // ms

  if (!form) {
    return;
  }

  const submitResponse = form.querySelector('[data-submit-response]');

  if (submitResponse) {
    submitResponse.classList.add('shown');
    setTimeout(() => {
      submitResponse.classList.remove('shown');
    }, RESPONSE_HIDE_TIMEOUT);
  }
}

export {showSubmitResponse};
