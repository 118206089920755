/* globals Swiper */
export function enableKLinesSlider() {
  const swiper = new Swiper('.knowledge-lines__slider', {
    slidesPerView: 1,
    spaceBetween: 39,
    a11y: {
      prevSlideMessage: 'Предыдущий слайд',
      nextSlideMessage: 'Следующий слайд',
      firstSlideMessage: 'Первый слайд',
      lastSlideMessage: 'Последний слайд',
    },
    navigation: {
      prevEl: '.knowledge-lines__btn--prev',
      nextEl: '.knowledge-lines__btn--next',
    },
  });

  return swiper;
}
