import {checkInput} from './check-input';

export function checkSubscribe() {
  const subscribeForm = document.querySelector('#subscribe-line');
  const email = document.querySelector('#subscribe-email');

  if (subscribeForm) {
    subscribeForm.addEventListener('submit', submitFormLineHandler);
    email.addEventListener('input', checkInput);
  }

  function submitFormLineHandler(evt) {
    evt.preventDefault();
    const container = subscribeForm.parentElement;
    if (email.value.length === 0) {
      container.classList.add('subscribe__form--error');
      return;
    }
    if (!email.value.includes('@')) {
      container.classList.add('subscribe__form--error');
      return;
    }
    container.classList.remove('subscribe__form--error');
    container.classList.add('subscribe__form--success');
  }
}
