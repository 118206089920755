export const initExchangeCurrency = () => {
  const lists = document.querySelectorAll('.exchange-currency');

  if (!lists.length) {
    return;
  }


  let activeSliderInstance = null;


  document.addEventListener('click', (evt) => {
    const currencyList = evt.target.closest('.exchange-currency');
    const currencyButton = evt.target.closest('.exchange-currency__button');
    const toggleButton = evt.target.closest('.exchange-currency__toggle-button');

    if (!currencyList) {
      return;
    }

    evt.stopPropagation();

    if (currencyButton) {
      const value = currencyButton.dataset.value;

      const currencyListOutput = currencyList.querySelector('.exchange-currency__output');

      const relatedOutput = document.querySelector(`[data-output="${ currencyList.dataset.id }"]`);
      const relatedInput = document.querySelector(`[data-exchange-currency="${ currencyList.dataset.id }"]`);

      currencyListOutput.textContent = value;

      if (relatedOutput) {
        relatedOutput.innerHTML = '';
        relatedOutput.append(currencyButton.querySelector('.exchange-currency__currency').cloneNode(true));
      }

      if (relatedInput) {
        relatedInput.value = value;
      }

      currencyList.classList.remove('is-expanded');

      if (activeSliderInstance) {
        activeSliderInstance.destroy();
        activeSliderInstance = null;
      }
    }

    if (toggleButton) {
      if (activeSliderInstance) {
        if (currencyList.classList.contains('is-expanded')) {
          window.dispatchEvent(new Event('exchange-currency-close-request'));
        } else {
          window.dispatchEvent(new Event('exchange-currency-close-request'));
          currencyList.classList.add('is-expanded');
          toggleButton.setAttribute('arial-label', toggleButton.dataset.closeText);
          activeSliderInstance = new window.Swiper(currencyList.querySelector('.exchange-currency__items-container'), {
            slidesPerView: 'auto',
          });
        }
      } else {
        currencyList.classList.add('is-expanded');
        toggleButton.setAttribute('arial-label', toggleButton.dataset.closeText);
        activeSliderInstance = new window.Swiper(currencyList.querySelector('.exchange-currency__items-container'), {
          slidesPerView: 'auto',
        });
      }
    }
  }, true);


  window.addEventListener('exchange-currency-close-request', () => {
    const expandedCurrencyList = document.querySelector('.exchange-currency.is-expanded');

    if (expandedCurrencyList) {
      expandedCurrencyList.classList.remove('is-expanded');

      if (activeSliderInstance) {
        activeSliderInstance.destroy();
        activeSliderInstance = null;
      }

      const toggleButton = expandedCurrencyList.querySelector('.exchange-currency__toggle-button');

      if (toggleButton) {
        toggleButton.setAttribute('arial-label', toggleButton.dataset.openText);
      }
    }
  });
};
