import {showSubmitResponse} from './submit-response';

function initSecurityForm() {
  const securityForm = document.querySelector('#security-form');

  if (!securityForm) {
    return;
  }

  securityForm.addEventListener('submit', (evt) => {
    evt.preventDefault();
    showSubmitResponse(securityForm);
  });
}

export {initSecurityForm};
