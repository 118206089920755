/* globals Swiper */
export function enableRatesSwiper() {
  const swiper = new Swiper('.rates-slider', {
    navigation: {
      prevEl: '.rates-slider__btn--prev',
      nextEl: '.rates-slider__btn--next',
    },
    a11y: {
      prevSlideMessage: 'Предыдущий слайд',
      nextSlideMessage: 'Следующий слайд',
      firstSlideMessage: 'Первый слайд',
      lastSlideMessage: 'Последний слайд',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  });

  return swiper;
}
