import {showSubmitResponse} from './submit-response';

function initProfileForm() {
  const profileForm = document.querySelector('#personal-data-form');

  if (!profileForm) {
    return;
  }

  const allInputs = profileForm.querySelectorAll('input, select');
  const submitButton = profileForm.querySelector('[type="submit"]');

  const checkIfAllFieldsEmpty = () => {
    if (allInputs.length && submitButton) {
      const allFieldsEmpty = Array.from(allInputs).every((item) => !item.value);
      submitButton.disabled = allFieldsEmpty;
    }
  };

  profileForm.addEventListener('input', () => {
    checkIfAllFieldsEmpty();
  });

  profileForm.addEventListener('submit', (evt) => {
    evt.preventDefault();
    showSubmitResponse(profileForm);
  });

  checkIfAllFieldsEmpty();
}

export {initProfileForm};
