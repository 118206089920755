function infoTooltip() {
  const tooltips = document.querySelectorAll('.info-tooltip');

  if (!tooltips.length) {
    return;
  }


  const OFFSET = 17;


  const showTooltip = function (evt) {
    const label = evt.target.closest('.info-tooltip');

    if (label) {
      label.classList.add('info-tooltip--shown');

      const tooltip = label.querySelector('.info-tooltip__text');
      const tooltipRect = tooltip.getBoundingClientRect();

      if (tooltipRect.left <= OFFSET) {
        tooltip.style.left = -(label.getBoundingClientRect().left - OFFSET) + 'px';
        tooltip.style.right = 'auto';
        return;
      }

      if (tooltipRect.right >= document.documentElement.clientWidth - OFFSET) {
        tooltip.style.right = -(document.documentElement.clientWidth - label.getBoundingClientRect().right - OFFSET) + 'px';
        tooltip.style.left = 'auto';
        return;
      }
    }
  };


  const hideTooltip = function (evt) {
    const label = evt.target.closest('.info-tooltip');

    if (label) {
      label.classList.remove('info-tooltip--shown');

      const tooltip = label.querySelector('.info-tooltip__text');
      tooltip.style = '';
    }
  };


  document.addEventListener('mouseover', showTooltip);
  document.addEventListener('mouseout', hideTooltip);

  document.addEventListener('focusin', showTooltip);
  document.addEventListener('focusout', hideTooltip);
}

export {infoTooltip};
