/* globals Selectr */

const VISIBLE_SELECT_ITEMS = 4;

const selects = [];

const isOverflown = (element) => {
  return element.children.length > VISIBLE_SELECT_ITEMS;
};

const createSelectr = (elem, config) => {
  if (elem.dataset) {
    const userConfig = elem.dataset;
    if (userConfig.customClass) {
      config.customClass = userConfig.customClass;
    }
    if (userConfig.noResults) {
      config.messages.noResults = userConfig.noResults;
    }
    if (userConfig.noOptions) {
      config.messages.noOptions = userConfig.noOptions;
    }
    if (userConfig.searchPlaceholder) {
      config.messages.searchPlaceholder = userConfig.searchPlaceholder;
    }
    if (userConfig.placeholder) {
      config.placeholder = userConfig.placeholder;
    }
  }
  const select = new Selectr((elem), config);

  if (select.items.length > VISIBLE_SELECT_ITEMS) {
    select.container.classList.add('selectr-container--overflown');
  }

  selects.push(select);
  select.on('selectr.open', () => {
    window.dispatchEvent(new CustomEvent('scrollbars-refresh-request', {
      detail: {
        delay: 20,
      },
    }));
  });
};

const setSelects = () => {
  const selectElements = document.querySelectorAll('.select');

  let configsGiving = {
    selectorRenderer: {
      searchable: true,
      closeOnScroll: false,
      width: 'initial',
      nativeKeyboard: true,
      customClass: 'giving-system',
      disabledMobile: true,
      messages: {
        noResults: 'Нет результатов',
        noOptions: 'Нет вариантов',
        searchPlaceholder: 'Поиск',
      },
      renderOption: renderer,
      renderSelection: renderer,
    },
  };

  let configsGetting = {
    selectorRenderer: {
      searchable: true,
      closeOnScroll: false,
      width: 'initial',
      nativeKeyboard: true,
      customClass: 'getting-system',
      disabledMobile: true,
      messages: {
        noResults: 'Нет результатов',
        noOptions: 'Нет вариантов',
        searchPlaceholder: 'Поиск',

      },
      renderOption: renderer,
      renderSelection: renderer,
    },
  };

  let configsGettingCurrency = {
    selectorRenderer: {
      searchable: false,
      closeOnScroll: false,
      width: 'initial',
      nativeKeyboard: true,
      customClass: 'getting-currency',
      disabledMobile: true,
      messages: {
        noResults: 'Нет результатов',
        noOptions: 'Нет вариантов',
        searchPlaceholder: 'Поиск',
      },
    },
  };

  const configSearchable = {
    searchable: true,
    closeOnScroll: false,
    width: 'initial',
    nativeKeyboard: true,
    disabledMobile: true,
    defaultSelected: false,
    messages: {
      noResults: 'Нет результатов',
      noOptions: 'Нет вариантов',
      searchPlaceholder: 'Поиск',

    },
    renderOption: renderer,
    renderSelection: renderer,
  };

  const configNotSearchable = {
    searchable: false,
    closeOnScroll: false,
    width: 'initial',
    nativeKeyboard: true,
    disabledMobile: true,
    messages: {
      noResults: 'Нет результатов',
      noOptions: 'Нет вариантов',
      searchPlaceholder: 'Поиск',
    },
  };

  if (document.getElementById('givingCurrencyTypeSelect')) {
    createSelectr(document.getElementById('givingCurrencyTypeSelect'), configsGiving.selectorRenderer);
  }

  if (document.getElementById('gettingSystemSelect')) {
    createSelectr(document.getElementById('gettingSystemSelect'), configsGetting.selectorRenderer);
  }

  if (document.getElementById('gettingCurrencySelect')) {
    createSelectr(document.getElementById('gettingCurrencySelect'), configsGettingCurrency.selectorRenderer);
  }

  if (document.getElementById('country-select')) {
    const config = Object.assign({}, configSearchable, {
      customClass: 'country-select',
      placeholder: 'Ваша страна<span class="asterisk">*</span>',
      renderSelection: null
    });
    createSelectr(document.getElementById('country-select'), config);
  }

  if (document.getElementById('document-select')) {
    const config = Object.assign({}, configNotSearchable, {
      customClass: 'document-select'
    });
    createSelectr(document.getElementById('document-select'), config);
  }

  if (document.getElementById('city-select')) {
    const config = Object.assign({}, configSearchable, {
      customClass: 'city-select',
      renderOption: null
    });
    createSelectr(document.getElementById('city-select'), config);
  }

  const currencySelects = document.querySelectorAll('.currency-input__currency-select');
  const paymentSystemSelects = document.querySelectorAll('.currency-input__payment-system-select');

  if (currencySelects) {
    const config = Object.assign({}, configNotSearchable, {
      customClass: 'currency-input__currency-select'
    });
    currencySelects.forEach((currencySelect) => {
      createSelectr(currencySelect, config);
    });
  }

  if (paymentSystemSelects) {
    const config = Object.assign({}, configSearchable, {
      defaultSelected: true,
      customClass: 'currency-input__payment-system-select'
    });
    paymentSystemSelects.forEach((paymentSystemSelect) => {
      createSelectr(paymentSystemSelect, config);
    });
  }

  // if (document.querySelector('#country-select')) {
  //   selects.push(new Selectr(document.getElementById('country-select'), {
  //     searchable: true,
  //     closeOnScroll: false,
  //     width: '100%',
  //     placeholder: 'Ваша страна<span>*</span>',
  //     customClass: 'country-select',
  //     defaultSelected: false,
  //     allowDeselect: true,
  //     nativeKeyboard: true,
  //     messages: {
  //       noResults: 'Не удалось найти такую страну',
  //       noOptions: 'Нет вариантов',
  //       searchPlaceholder: 'Поиск',
  //     },
  //     renderOption: renderer,
  //   }));
  // }

  // if (document.querySelector('#document-select')) {
  //   const selectElement = document.getElementById('document-select');
  //   selects.push(new Selectr(selectElement, {
  //     searchable: false,
  //     closeOnScroll: false,
  //     width: '100%',
  //     placeholder: selectElement.dataset.placeholder ? selectElement.dataset.placeholder : 'Выберите документ*',
  //     customClass: 'document-select',
  //     defaultSelected: false,
  //     allowDeselect: true,
  //     nativeKeyboard: true,
  //   }));
  // }

  // if (document.querySelector('#city-select')) {
  //   const selectElement = document.getElementById('city-select');
  //   selects.push(new Selectr(selectElement, {
  //     searchable: true,
  //     closeOnScroll: false,
  //     width: '100%',
  //     placeholder: selectElement.dataset.placeholder ? selectElement.dataset.placeholder : 'Ваш город*',
  //     customClass: 'city-select',
  //     defaultSelected: false,
  //     allowDeselect: true,
  //     nativeKeyboard: true,
  //     messages: {
  //       noResults: 'Не удалось найти такой город',
  //       noOptions: 'Нет вариантов',
  //       searchPlaceholder: 'Поиск',
  //     },
  //     renderOption: renderer,
  //   }));
  // }

  // const currencySelects = document.querySelectorAll('.currency-input__currency-select');
  // const paymentSystemSelects = document.querySelectorAll('.currency-input__payment-system-select');

  // if (currencySelects) {
  //   currencySelects.forEach((currencySelect) => {
  //     selects.push(new Selectr(currencySelect, {
  //       searchable: false,
  //       closeOnScroll: false,
  //       width: 'initial',
  //       customClass: 'currency-input__currency-select',
  //       nativeKeyboard: true,
  //     }));
  //   });
  // }

  // if (paymentSystemSelects) {
  //   paymentSystemSelects.forEach((paymentSystemSelect) => {
  //     selects.push(new Selectr(paymentSystemSelect, {
  //       searchable: true,
  //       closeOnScroll: false,
  //       width: 'initial',
  //       customClass: 'currency-input__payment-system-select',
  //       nativeKeyboard: true,
  //       messages: {
  //         noResults: 'Нет результатов',
  //         noOptions: 'Нет вариантов',
  //         searchPlaceholder: 'Поиск',
  //       },
  //       renderOption: renderer,
  //       renderSelection: renderer,
  //     }));
  //   });
  // }

  if (!selectElements.length) {
    return;
  }

  selectElements.forEach((selectElement) => {
    const dropdown = selectElement.querySelector('.dropdown');
    const menu = selectElement.querySelector('.ui.dropdown .menu');

    if (isOverflown(menu)) {
      selectElement.classList.add('select--overflown');
    }

    window.$(dropdown).dropdown({
      forceSelection: false,
      direction: 'downward',
      onShow: () => {
        window.dispatchEvent(new CustomEvent('scrollbars-refresh-request', {
          detail: {
            delay: 200,
          },
        }));
      },
    });
  });

  function renderer(data) {
    const iconSrc = data.dataset ? data.dataset.icon : data.icon;
    let template = [
      '<div class=\'icon-template\'><img src=\'', iconSrc, '\'><span>',
      data.text,
      '</span></div>'
    ];
    return template.join('');
  }
};


// const setSelect = function () {
//   const selects = [];

//   let configsGiving = {
//     selectorRenderer: {
//       searchable: true,
//       closeOnScroll: false,
//       width: 'initial',
//       nativeKeyboard: true,
//       customClass: 'giving-currency',
//       messages: {
//         noResults: 'Нет результатов',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//       renderOption: renderer,
//       renderSelection: renderer,
//     },
//   };

//   let configsGetting = {
//     selectorRenderer: {
//       searchable: true,
//       closeOnScroll: false,
//       width: 'initial',
//       nativeKeyboard: true,
//       customClass: 'getting-currency',
//       messages: {
//         noResults: 'Нет результатов',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//       renderOption: renderer,
//       renderSelection: renderer,
//     },
//   };

//   if (document.getElementById('givingCurrencyTypeSelect')) {
//     selects.push(new Selectr(document.getElementById('givingCurrencyTypeSelect'), configsGiving.selectorRenderer));
//   }

//   if (document.getElementById('gettingCurrencyTypeSelect')) {
//     selects.push(new Selectr(document.getElementById('gettingCurrencyTypeSelect'), configsGetting.selectorRenderer));
//   }

//   if (document.getElementById('givingCurrencySelect')) {
//     selects.push(new Selectr(document.getElementById('givingCurrencySelect'), {
//       searchable: false,
//       closeOnScroll: false,
//       width: 'initial',
//       nativeKeyboard: true,
//       customClass: 'currency',
//       messages: {
//         noResults: 'Нет результатов',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//     }));
//   }

//   if (document.getElementById('gettingCurrencySelect')) {
//     selects.push(new Selectr(document.getElementById('gettingCurrencySelect'), {
//       searchable: false,
//       closeOnScroll: false,
//       width: 'initial',
//       nativeKeyboard: true,
//       customClass: 'currency',
//       messages: {
//         noResults: 'Нет результатов',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//     }));
//   }

//   let configsGivingMain = {
//     selectorRenderer: {
//       searchable: true,
//       closeOnScroll: false,
//       width: '100%',
//       nativeKeyboard: true,
//       customClass: 'exchange__giving-currency',
//       messages: {
//         noResults: 'Нет результатов',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//       renderOption: renderer,
//       renderSelection: renderer,
//     },
//   };

//   let configsGettingMain = {
//     selectorRenderer: {
//       searchable: true,
//       closeOnScroll: false,
//       width: '100%',
//       nativeKeyboard: true,
//       customClass: 'exchange__getting-currency',
//       messages: {
//         noResults: 'Нет результатов',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//       renderOption: renderer,
//       renderSelection: renderer,
//     },
//   };

//   if (document.querySelector('#givingCurrencyTypeSelectMain')) {
//     selects.push(new Selectr(document.getElementById('givingCurrencyTypeSelectMain'), configsGivingMain.selectorRenderer));
//   }

//   if (document.querySelector('#gettingCurrencyTypeSelectMain')) {
//     selects.push(new Selectr(document.getElementById('gettingCurrencyTypeSelectMain'), configsGettingMain.selectorRenderer));
//   }

//   if (document.querySelector('#givingCurrencySelectMain')) {
//     selects.push(new Selectr(document.getElementById('givingCurrencySelectMain'), {
//       searchable: false,
//       closeOnScroll: false,
//       customClass: 'exchange__currency',
//       messages: {
//         noResults: 'Нет результатов',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//     }));
//   }

//   if (document.querySelector('#gettingCurrencySelectMain')) {
//     selects.push(new Selectr(document.getElementById('gettingCurrencySelectMain'), {
//       searchable: false,
//       closeOnScroll: false,
//       customClass: 'exchange__currency',
//       messages: {
//         noResults: 'Нет результатов',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//     }));
//   }

//   if (document.querySelector('#cardholderCountrySelect')) {
//     selects.push(new Selectr(document.getElementById('cardholderCountrySelect'), {
//       searchable: false,
//       closeOnScroll: false,
//       width: '100%',
//       placeholder: 'Выберите свою страну<span>*</span>',
//       customClass: 'country-select',
//       defaultSelected: false,
//       allowDeselect: true,
//       nativeKeyboard: true,
//       messages: {
//         noResults: 'Не удалось найти такую страну',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//       renderOption: renderer,
//     }));
//   }

//   if (document.querySelector('#country-select')) {
//     selects.push(new Selectr(document.getElementById('country-select'), {
//       searchable: true,
//       closeOnScroll: false,
//       width: '100%',
//       placeholder: 'Ваша страна<span>*</span>',
//       customClass: 'country-select',
//       defaultSelected: false,
//       allowDeselect: true,
//       nativeKeyboard: true,
//       messages: {
//         noResults: 'Не удалось найти такую страну',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//       renderOption: renderer,
//     }));
//   }

//   if (document.querySelector('#document-select')) {
//     const selectElement = document.getElementById('document-select');
//     selects.push(new Selectr(selectElement, {
//       searchable: false,
//       closeOnScroll: false,
//       width: '100%',
//       placeholder: selectElement.dataset.placeholder ? selectElement.dataset.placeholder : 'Выберите документ*',
//       customClass: 'document-select',
//       defaultSelected: false,
//       allowDeselect: true,
//       nativeKeyboard: true,
//     }));
//   }

//   if (document.querySelector('#city-select')) {
//     const selectElement = document.getElementById('city-select');
//     selects.push(new Selectr(selectElement, {
//       searchable: true,
//       closeOnScroll: false,
//       width: '100%',
//       placeholder: selectElement.dataset.placeholder ? selectElement.dataset.placeholder : 'Ваш город*',
//       customClass: 'city-select',
//       defaultSelected: false,
//       allowDeselect: true,
//       nativeKeyboard: true,
//       messages: {
//         noResults: 'Не удалось найти такой город',
//         noOptions: 'Нет вариантов',
//         searchPlaceholder: 'Поиск',
//       },
//       renderOption: renderer,
//     }));
//   }

//   const currencySelects = document.querySelectorAll('.currency-input__currency-select');
//   const paymentSystemSelects = document.querySelectorAll('.currency-input__payment-system-select');

//   if (currencySelects) {
//     currencySelects.forEach((currencySelect) => {
//       selects.push(new Selectr(currencySelect, {
//         searchable: false,
//         closeOnScroll: false,
//         width: 'initial',
//         customClass: 'currency-input__currency-select',
//         nativeKeyboard: true,
//       }));
//     });
//   }

//   if (paymentSystemSelects) {
//     paymentSystemSelects.forEach((paymentSystemSelect) => {
//       selects.push(new Selectr(paymentSystemSelect, {
//         searchable: true,
//         closeOnScroll: false,
//         width: 'initial',
//         customClass: 'currency-input__payment-system-select',
//         nativeKeyboard: true,
//         messages: {
//           noResults: 'Нет результатов',
//           noOptions: 'Нет вариантов',
//           searchPlaceholder: 'Поиск',
//         },
//         renderOption: renderer,
//         renderSelection: renderer,
//       }));
//     });
//   }

//   function renderer(data) {
//     const iconSrc = data.dataset ? data.dataset.icon : data.icon;
//     let template = [
//       '<div class=\'icon-template\'><img src=\'', iconSrc, '\'><span>',
//       data.text,
//       '</span></div>'
//     ];
//     return template.join('');
//   }

//   return selects;
// };

export {setSelects};
