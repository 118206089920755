const setLanguage = function () {
  const languageSelects = document.querySelectorAll('.language-select');

  if (!languageSelects.length) {
    return;
  }

  languageSelects.forEach((languageSelect) => {
    const languageButton = languageSelect.querySelector('[data-current-language]');
    const languageOptions = languageSelect.querySelectorAll('[data-language]');

    if (!languageOptions.length) {
      return;
    }

    const listener = (evt) => {
      if (!evt.target.closest('.language-select--active')) {
        closeMenu();
      }
    };

    const closeMenu = () => {
      languageSelect.classList.remove('language-select--active');
      document.removeEventListener('click', listener);
    };

    const openMenu = () => {
      languageSelect.classList.add('language-select--active');
      document.addEventListener('click', listener);
    };

    const showAllOptions = () => {
      languageOptions.forEach((option) => {
        option.classList.remove('language-select__item--hidden');
      });
    };

    const hideOption = (languageSelectItem) => {
      if (languageSelectItem) {
        languageSelectItem.classList.add('language-select__item--hidden');
      }
    };

    const refreshList = () => {
      const currentLanguageElement = Array.from(languageOptions).find((option) => {
        return option.dataset.language === languageButton.dataset.currentLanguage;
      });

      showAllOptions();
      hideOption(currentLanguageElement);
    };

    const setCurrentLanguage = (value, text) => {
      languageButton.textContent = text;
      languageButton.dataset.currentLanguage = value;
      refreshList();
    };

    languageButton.addEventListener('click', () => {
      if (languageSelect.classList.contains('language-select--active')) {
        closeMenu();
      } else {
        openMenu();
      }
    });

    for (let i = 0; i < languageOptions.length; i++) {
      languageOptions[i].addEventListener('click', function (evt) {
        setCurrentLanguage(evt.target.dataset.language, evt.target.textContent);
        evt.preventDefault();
        closeMenu();
      });
    }

    refreshList();
  });
};

export {setLanguage};
