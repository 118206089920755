export function checkReserveReq() {
  const form = document.querySelector('#reserve-req-form');
  if (!form) {
    return;
  }
  const email = form.querySelector('#reserve-req-email');
  const sumInp = form.querySelector('#reserve-req-sum');
  const telInp = form.querySelector('#reserve-req-telegram');
  const buttonSelect = form.querySelector('#modal-req-select-btn');
  const selectList = form.querySelector('.modal__select-list');
  const options = [...form.querySelectorAll('.modal__select-it')];
  const optionsClasses = options.map((item) => String(item.className));
  const plug = form.querySelector('.modal__select-plug');


  function checkForm(evt) {
    const submitButton = evt.currentTarget.closest('form').querySelector('button[type="submit"]');
    if ((email.value.length === 0 && telInp.value.length === 0) || sumInp.value.length === 0) {
      submitButton.disabled = true;
      return;
    }
    submitButton.disabled = false;
  }

  function toggleSelectList(evt) {
    evt.preventDefault();
    selectList.classList.toggle('modal__select-list--hidden');
  }

  function choseInput(evt) {
    if (plug) {
      plug.remove();
    }
    const i = optionsClasses.findIndex((item) => {
      return item.includes(evt.target.dataset.contact);
    });
    if (options[i].classList.contains('modal__select-it--shown')) {
      return;
    }
    options.forEach((item) => item.classList.remove('modal__select-it--shown'));
    options[i].classList.add('modal__select-it--shown');
    selectList.classList.add('modal__select-list--hidden');
  }

  buttonSelect.addEventListener('click', toggleSelectList);
  selectList.addEventListener('click', choseInput);

  email.addEventListener('input', checkForm);
  sumInp.addEventListener('input', checkForm);
  telInp.addEventListener('input', checkForm);
}
