import {countdown} from './countdown';

export function modalCodesCheck(inputEmail, inputSMS, time, messageLink) {
  function checkForm(evt) {
    const submitButton = evt.currentTarget.closest('form').querySelector('button[type="submit"]');
    if (inputEmail.value.length === 0 || inputSMS.value.length === 0) {
      submitButton.disabled = true;
      return;
    }
    submitButton.disabled = false;
  }

  function onSendAgain(evt) {
    evt.preventDefault();
    time.closest('p').classList.remove('visibility-hidden');
    messageLink.classList.add('visibility-hidden');
    start();
  }

  function start() {
    countdown(time, 120).then(() => {
      time.closest('p').classList.add('visibility-hidden');
      messageLink.classList.remove('visibility-hidden');
    });
  }


  inputEmail.addEventListener('input', checkForm);
  inputSMS.addEventListener('input', checkForm);
  messageLink.addEventListener('click', onSendAgain);
  start();
}
