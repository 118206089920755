const closeLastExchanges = () => {
  let lastExchanges = document.querySelector('.last-exchanges');
  let lastExchangesClose = document.querySelector('.last-exchanges__close');

  if (lastExchangesClose) {
    lastExchangesClose.addEventListener('click', () => {
      lastExchanges.classList.add('last-exchanges--closed');
    });
  }
};

export {
  closeLastExchanges
};
