const togglePromo = () => {
  let promo = document.querySelector('.promo');
  let promoSlides = document.querySelectorAll('.promo__slide');
  let promoPagination = document.querySelector('.promo__pagination');
  let promoImages = document.querySelector('.promo__images');
  let promoToggle = document.querySelector('.promo__button');
  let promoContainer = document.querySelector('.promo__container');
  let promoExchange = document.querySelector('.promo__exchange');

  if (promoToggle) {
    promoToggle.addEventListener('click', () => {
      promoToggle.classList.toggle('promo__button--closed');
      promo.classList.toggle('promo--closed');
      promoSlides.forEach((slide) => {
        slide.classList.toggle('promo__slide--closed');
      });
      promoPagination.classList.toggle('promo__pagination--closed');
      promoImages.classList.toggle('promo__images--closed');
      promoContainer.classList.toggle('promo__container--closed');
      promoExchange.classList.toggle('promo__exchange--closed');
    });
  }
};

export {
  togglePromo
};
