const manageApplicationModal = function () {
  let applicationModal = document.querySelector('.modal--application');

  function startTimer(duration, clocks) {
    let timer = duration.substring(3, 5) * 60;
    let intervalId = setInterval(function () {

      clocks.innerHTML = 'Отправить повторно ' + '<span>' + '(' + timer + ' сек.' + ')' + '</span>';

      if (--timer < 0) {
        timer = 0;
        clocks.innerHTML = 'Отправить повторно';
        clocks.removeAttribute('disabled');
        clocks.classList.remove('modal__resend--disabled');
        clearInterval(intervalId);
      }
    }, 1000);
  }

  if (applicationModal && applicationModal.querySelector('.modal__resend')) {
    let applicationResendButton = applicationModal.querySelector('.modal--application .modal__resend');
    applicationResendButton.addEventListener('click', function () {
      let duration = applicationResendButton.dataset.resendTime;
      applicationResendButton.setAttribute('disabled', 'disabled');
      applicationResendButton.classList.add('modal__resend--disabled');
      startTimer(duration, applicationResendButton);
    });
  }
};

export {
  manageApplicationModal
};
