import {checkInput} from './check-input';
import {countdown} from './countdown';

export function restorePassword() {
  const contactInput = document.querySelector('#restore-contact');
  const codeInput = document.querySelector('#restore-code');
  const passwordInput = document.querySelector('#restore-password');
  const confirmInput = document.querySelector('#restore-confirm');
  const countDownElement = document.querySelector('.restore__countdown');
  const restoreContactForm = document.querySelector('#restore-contact-form');
  const restoreNewtForm = document.querySelector('#restore-new-form');

  function checkForm(evt) {
    const submitButton = evt.currentTarget.closest('form').querySelector('button[type="submit"]');
    if (passwordInput.value.length === 0 || confirmInput.value.length === 0) {
      submitButton.disabled = true;
      return;
    }
    submitButton.disabled = false;
  }

  function contactSubmitHandler(evt) {
    evt.preventDefault();
    evt.currentTarget.querySelector('.restore-form__message').classList.add('restore-form__message--show');
    const submitBtn = restoreContactForm.querySelector('button[type="submit"]');
    submitBtn.disabled = true;
    countdown(countDownElement)
        .then(() => {
          submitBtn.disabled = false;
        });
  }

  function newPassportSetHandler(evt) {
    evt.preventDefault();
    document.querySelector('.restore__block-success').classList.add('restore__block-success--show');
  }

  if (contactInput) {
    contactInput.addEventListener('input', checkInput);
  }
  if (codeInput) {
    codeInput.addEventListener('input', checkInput);
  }
  if (passwordInput && confirmInput) {
    passwordInput.addEventListener('input', checkForm);
    confirmInput.addEventListener('input', checkForm);
  }

  if (restoreContactForm) {
    restoreContactForm.addEventListener('submit', contactSubmitHandler);
  }
  if (restoreNewtForm) {
    restoreNewtForm.addEventListener('submit', newPassportSetHandler);
  }
}
