/* globals Swiper */
const enableLastExchangesSlider = () => {
  const promo = new Swiper('.last-exchanges__slider', {
    a11y: {
      notificationClass: 'last-exchanges__notification',
      prevSlideMessage: 'Предыдущий слайд',
      nextSlideMessage: 'Следующий слайд',
      firstSlideMessage: 'Первый слайд',
      lastSlideMessage: 'Последний слайд',
    },
    containerModifierClass: 'last-exchanges__slider--',
    slideClass: 'last-exchanges__slide',
    slideActiveClass: 'last-exchanges__slide--active',
    slideNextClass: 'last-exchanges__slide--next',
    slidePrevClass: 'last-exchanges__slide--prev',
    wrapperClass: 'last-exchanges__wrapper',
    direction: 'vertical',
    slidesPerView: 4,
    speed: 1000,
    initialSlide: 11,
    spaceBetween: 3,
    autoplay: {
      delay: 3000,
      reverseDirection: true,
      stopOnLastSlide: true,
    },
  });

  return promo;
};


export {
  enableLastExchangesSlider
};
