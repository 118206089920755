/* globals Swiper */
const enablePromoSlider = () => {
  const promo = new Swiper('.promo__slider', {
    a11y: {
      notificationClass: 'promo__notification',
      prevSlideMessage: 'Предыдущий слайд',
      nextSlideMessage: 'Следующий слайд',
      firstSlideMessage: 'Первый слайд',
      lastSlideMessage: 'Последний слайд',
      paginationBulletMessage: 'Перейти к слайду {{index}}',
    },
    pagination: {
      el: '.promo__pagination',
      clickable: true,
      bulletClass: 'promo__bullet',
      bulletElement: 'button',
      bulletActiveClass: 'promo__bullet--active',
      clickableClass: 'promo__pagination--clickable',
      modifierClass: 'promo__pagination--',
    },
    containerModifierClass: 'promo__slider--',
    slideClass: 'promo__slide',
    slideActiveClass: 'promo__slide--active',
    slideNextClass: 'promo__slide--next',
    slidePrevClass: 'promo__slide--prev',
    wrapperClass: 'promo__wrapper',
    slidesPerView: 1,
    slidesPerGroup: 1,
    autoplay: {
      delay: 6000,
    },
  });

  return promo;
};


export {
  enablePromoSlider
};
