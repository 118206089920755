const VISIBLE_SELECT_ITEMS = 4;

const isOverflown = (element) => {
  return element.children.length > VISIBLE_SELECT_ITEMS;
};

const setDatalist = function () {
  class DataList {
    constructor(containerId, inputId, listId, options = []) {
      this.containerId = containerId;
      this.inputId = inputId;
      this.listId = listId;

      const list = document.getElementById(this.listId);

      if (list && list.children.length > 0) {
        this.options = Array.from(list.children).map((item) => item.dataset);
      } else {
        this.options = options;
      }
    }

    create(filter = '') {
      const container = document.getElementById(this.containerId);
      const list = document.getElementById(this.listId);
      const filterOptions = this.options.filter(
          (d) => filter === '' || d.text.includes(filter)
      );

      if (list) {

        if (filterOptions.length === 0) {
          container.classList.remove('active');
        } else {
          list.classList.add('active');
        }

        list.innerHTML = filterOptions
            .map((o) => `<button type="button" value=${o.value}>${o.text}</button>`)
            .join('');
      }
    }

    createListWithErrorTooltip(filter = '') {
      const container = document.getElementById(this.containerId);
      const list = document.getElementById(this.listId);

      if (list) {
        const tooltip = container.querySelector('.tooltip__text');
        const filterOptions = this.options.filter(
            (d) => filter === '' || d.text.toLowerCase().includes(filter.toLowerCase())
        );

        if (filterOptions.length === 0) {
          container.classList.remove('active');
          tooltip.classList.add('tooltip__text--active');
        } else {
          list.classList.add('active');
          tooltip.classList.remove('tooltip__text--active');
        }

        list.innerHTML = filterOptions
            .map((o) => `<button type="button" value=${o.value}>${o.text}</button>`)
            .join('');
      }
    }

    createListWithIcon(filter = '') {
      const list = document.getElementById(this.listId);
      const filterOptions = this.options.filter(
          (d) => filter === '' || d.text.includes(filter)
      );

      if (filterOptions.length === 0) {
        list.classList.remove('active');
      } else {
        list.classList.add('active');
      }

      list.innerHTML = filterOptions
          .map((o) => `<li>${o.text}<img src=${o.icon}></li>`)
          .join('');
    }


    createTelephoneList(filter = '') {
      const container = document.getElementById(this.containerId);
      const list = document.getElementById(this.listId);
      const filterOptions = this.options.filter(
          (d) => filter === '' || d.text.includes(filter) || d.country.includes(filter)
      );

      if (list) {

        if (isOverflown(list)) {
          list.classList.add('datalist__ul--overflown');
        }

        if (filterOptions.length === 0) {
          container.classList.remove('active');
        } else {
          list.classList.add('active');
        }

        list.innerHTML = filterOptions
            .map((o) => `<button type="button" value=${o.value}>${o.text}<img src=${o.icon}><span>${o.country}</span></button>`)
            .join('');
      }
    }

    addListeners(datalist) {
      const container = document.getElementById(this.containerId);
      const input = document.getElementById(this.inputId);
      const list = document.getElementById(this.listId);

      if (container) {
        container.addEventListener('click', (e) => {
          if (e.target.id === this.inputId) {
            container.classList.toggle('active');
          } else if (e.target.nodeName.toLocaleLowerCase() === 'i') {
            container.classList.toggle('active');
            input.focus();
          }

          window.dispatchEvent(new CustomEvent('scrollbars-refresh-request', {
            detail: {
              delay: 20,
            },
          }));
        });

        input.addEventListener('input', function () {
          if (!container.classList.contains('active')) {
            container.classList.add('active');
          }

          datalist.create(input.value);
        });

        list.addEventListener('click', function (e) {
          if (e.target.nodeName.toLocaleLowerCase() === 'button') {
            input.value = e.target.innerText;
            container.classList.remove('active');
          }

          window.dispatchEvent(new CustomEvent('scrollbars-refresh-request', {
            detail: {
              delay: 20,
            },
          }));
        });

        document.body.addEventListener('keydown', function (evt) {
          if (evt.key === 'Escape' || evt.key === 'Esc') {
            if (container.classList.contains('active')) {
              container.classList.remove('active');
              input.blur();
            }
          }
        });
      }
    }

    addListenersWithTooltip(datalist) {
      const container = document.getElementById(this.containerId);
      const input = document.getElementById(this.inputId);
      const list = document.getElementById(this.listId);

      if (container) {
        container.addEventListener('click', (e) => {
          if (e.target.id === this.inputId) {
            container.classList.toggle('active');
          } else if (e.target.nodeName.toLocaleLowerCase() === 'i') {
            container.classList.toggle('active');
            input.focus();
          }

          window.dispatchEvent(new CustomEvent('scrollbars-refresh-request', {
            detail: {
              delay: 20,
            },
          }));
        });

        input.addEventListener('input', function () {
          if (!container.classList.contains('active')) {
            container.classList.add('active');
          }

          datalist.createListWithErrorTooltip(input.value);
        });

        list.addEventListener('click', function (e) {
          if (e.target.nodeName.toLocaleLowerCase() === 'button') {
            input.value = e.target.innerText;
            container.classList.remove('active');
          }

          window.dispatchEvent(new CustomEvent('scrollbars-refresh-request', {
            detail: {
              delay: 20,
            },
          }));
        });

        document.body.addEventListener('keydown', function (evt) {
          if (evt.key === 'Escape' || evt.key === 'Esc') {
            if (container.classList.contains('active')) {
              container.classList.remove('active');
              input.blur();
            }
          }
        });
      }
    }

    addListenersTelephone(datalist) {
      const container = document.getElementById(this.containerId);
      const input = document.getElementById(this.inputId);
      const list = document.getElementById(this.listId);

      if (container) {
        container.addEventListener('click', (e) => {
          if (e.target.id === this.inputId) {
            container.classList.toggle('active');
          } else if (e.target.nodeName.toLocaleLowerCase() === 'i') {
            container.classList.toggle('active');
            input.focus();
          }

          window.dispatchEvent(new CustomEvent('scrollbars-refresh-request', {
            detail: {
              delay: 20,
            },
          }));
        });

        input.addEventListener('input', function () {
          if (!container.classList.contains('active')) {
            container.classList.add('active');
          }

          datalist.createTelephoneList(input.value);
        });


        list.addEventListener('click', function (e) {
          if (e.target.nodeName.toLocaleLowerCase() === 'button') {
            let optionValue = e.target.value;
            let optionImg = e.target.querySelector('img').src;
            container.querySelector('img').src = optionImg;
            input.value = '';
            input.placeholder = optionValue + ' ' + '0000000000';
            input.focus();
            container.classList.remove('active');
          }
        });


        document.body.addEventListener('keydown', function (evt) {
          if (evt.key === 'Escape' || evt.key === 'Esc') {
            if (container.classList.contains('active')) {
              container.classList.remove('active');
              input.blur();
            }
          }
        });
      }
    }
  }

  const datalist = new DataList(
      'datalist',
      'datalist-input',
      'datalist-ul'
  );

  const gettingCityDatalist = new DataList(
      'getting-cardholder-city',
      'getting-cardholder-city-input',
      'getting-cardholder-city-ul'
  );

  const gettingCountryDatalist = new DataList(
      'getting-cardholder-country',
      'getting-cardholder-country-input',
      'getting-cardholder-country-ul'
  );

  const telephoneDatalist = new DataList(
      'datalist-telephone',
      'datalist-input-telephone',
      'datalist-ul-telephone'
  );

  const cardHolderTelephoneDatalist = new DataList(
      'datalist-cardholder-telephone',
      'datalist-input-cardholder-telephone',
      'datalist-ul-cardholder-telephone'
  );

  datalist.create();
  datalist.addListeners(datalist);
  gettingCityDatalist.create();
  gettingCityDatalist.addListeners(gettingCityDatalist);
  gettingCountryDatalist.createListWithErrorTooltip();
  gettingCountryDatalist.addListenersWithTooltip(gettingCountryDatalist);
  telephoneDatalist.createTelephoneList();
  telephoneDatalist.addListenersTelephone(telephoneDatalist);
  cardHolderTelephoneDatalist.createTelephoneList();
  cardHolderTelephoneDatalist.addListenersTelephone(cardHolderTelephoneDatalist);
};

export {setDatalist};
