const setTimer = function () {
  function startTimer(duration, clocks) {
    let timer = duration.substring(3, 5) * 60; let minutes; let seconds;
    let intervalId = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      if (clocks) {

        clocks.textContent = '' + minutes + ':' + seconds + '';

      }

      if (--timer < 0) {
        timer = 0;
        clearInterval(intervalId);
      }
    }, 1000);
  }

  window.onload = function () {
    let clocks = document.querySelector('.exchange-info__time');
    if (clocks) {
      let duration = clocks.dataset.applicationTime;
      startTimer(duration, clocks);
    }
  };
};

export {setTimer};
