import {iosVhFix} from './utils/ios-vh-fix';

import {initModals} from './modules/modals/init-modals';

import {toggleHeaderMenu} from './modules/toggle-header-menu';
import {toggleFooterMenu} from './modules/toggle-footer-menu';
import {setAnchors} from './modules/anchor-link';
import {validationForm} from './modules/form-authorization';
import {questionsAnswers} from './modules/faq';
import {manageApplicationModal} from './modules/modal-application';
import {manageFileUploaders} from './modules/upload-file';
import {setSelects} from './modules/select';
import {setLanguage} from './modules/language-select';
import {exchange} from './modules/exchange';
import {initExchangeCurrency} from './modules/exchange-currency';
import {initPaymentSystems} from './modules/payment-systems';
import {changeExchangeForm} from './modules/exchange-form';
import {setDatalist} from './modules/datalist';
import {initScrollbar} from './modules/select-scrollbar';
import {initDate} from './modules/date';
import {setTimer} from './modules/application-timer';
import {setCopyButton} from './modules/copy-button';
import {setRating} from './modules/rating';
import {enableCertificatesSlider} from './modules/slider-certificates';
import {changeCardShape} from './modules/card-shape';
import {enableReservesSlider} from './modules/slider-reserves';
import {enableMainReviewsSlider} from './modules/slider-main-reviews';
import {enablePromoSlider} from './modules/slider-promo';
import {togglePromo} from './modules/promo-toggle';
import {enableLastExchangesSlider} from './modules/slider-last-exchanges';
import {closeLastExchanges} from './modules/last-exchanges-close';
import {enableLastExchangesScroll} from './modules/last-exchanges-scroll';
import {checkSubscribe} from './modules/subscribe-line';
import {setServiceTimer} from './modules/service-timer';
import {togglePassword} from './modules/toggle-password';
import {restorePassword} from './modules/restore-password';
import {enableRatesSwiper} from './modules/rates-slider';
import {enableKLinesSlider} from './modules/knowledge-lines-slider';
import {enableIntSlider} from './modules/int-slider';
import {enableNewsSlider} from './modules/news-slider';
import {checkRegistration} from './modules/registartion';
import {checkReserveReq} from './modules/modal-reserve-request';
import {tabsSelect} from './modules/tabs-select';
import {currentDate} from './modules/current-date';
import {manage2faModal} from './modules/modal-2fa';
import {infoTooltip} from './modules/info-tooltip';
import {checkModalCodeReg} from './modules/modal-codes-reg';
import {checkModalCodeAuth} from './modules/modal-codes-auth';
import {initBackToTop} from './modules/back-to-top';
import {initProfileForm} from './modules/profile-form';
import {initAccountsForm} from './modules/accounts-form';
import {initSecurityForm} from './modules/security-form';
import {clampText} from './modules/clamping';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();

  // Modules
  // ---------------------------------

  toggleHeaderMenu();
  toggleFooterMenu();
  clampText();
  setAnchors();
  validationForm();
  currentDate();
  questionsAnswers();
  manageApplicationModal();
  manageFileUploaders();
  setLanguage();
  exchange();
  initExchangeCurrency();
  initPaymentSystems();
  changeExchangeForm();
  setDatalist();
  initDate();
  setTimer();
  setCopyButton();
  setRating();
  enableCertificatesSlider();
  changeCardShape();
  enableReservesSlider();
  enableMainReviewsSlider();
  enablePromoSlider();
  togglePromo();
  enableLastExchangesSlider();
  closeLastExchanges();
  enableLastExchangesScroll();
  checkSubscribe();
  setServiceTimer();
  togglePassword();
  restorePassword();
  enableRatesSwiper();
  enableKLinesSlider();
  enableIntSlider();
  enableNewsSlider();
  restorePassword();
  checkRegistration();
  checkReserveReq();
  tabsSelect();
  manage2faModal();
  infoTooltip();
  checkModalCodeReg();
  checkModalCodeAuth();
  setSelects();

  initProfileForm();
  initAccountsForm();
  initSecurityForm();

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initModals();
    initBackToTop();
    initScrollbar();
  });
});

// ---------------------------------

// используйте .closest(el)

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// привязывайте js не на бэм, а на data-cookie

// выносим все в data-attr - url до пинов карты, настройки автопрокрутки, url к json и т.д.
