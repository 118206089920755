import {showSubmitResponse} from './submit-response';
import {initialFormListener} from './form-changed';

function initAccountsForm() {
  const accountsForm = document.querySelector('#accounts-form');

  if (!accountsForm) {
    return;
  }

  const paymentSystemSelect = accountsForm.querySelector('#account-payment-system');
  const currencySelect = accountsForm.querySelector('#account-currency');
  const accountId = accountsForm.querySelector('#account-id');
  const tableRowTemplate = document.querySelector('#accounts-table-row');

  if (paymentSystemSelect && currencySelect && accountId && tableRowTemplate) {
    accountsForm.addEventListener('submit', (evt) => {
      const tableRow = tableRowTemplate.content.querySelector('.accounts-table__row').cloneNode(true);
      const paymentSystemInput = tableRow.querySelector('.accounts-table__payment-system-name');
      const currencyInput = tableRow.querySelector('.accounts-table__currency');
      const accountIdInput = tableRow.querySelector('.accounts-table__td--id');
      const table = document.querySelector('.accounts-table__body');

      if (tableRow && paymentSystemInput && currencyInput && accountIdInput && table) {
        evt.preventDefault();

        paymentSystemInput.textContent = paymentSystemSelect.value;
        currencyInput.textContent = currencySelect.value;
        accountIdInput.textContent = accountId.value;

        table.appendChild(tableRow);
      }
    });
  }

  const submitButton = accountsForm.querySelector('[type="submit"]');

  const isListenerSet = initialFormListener(accountsForm, (allFieldsInitial) => {
    submitButton.disabled = allFieldsInitial;
  });

  if (isListenerSet) {
    submitButton.disabled = true;
  }

  accountsForm.addEventListener('submit', (evt) => {
    evt.preventDefault();
    showSubmitResponse(accountsForm);
  });
}

export {initAccountsForm};
