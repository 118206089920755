/* globals Datepicker */

const initDate = function () {
  const datepickerInput = document.querySelector('#datepicker');
  const datepickerLabel = document.querySelector('label[for="datepicker"]');
  let dateCalender = document.querySelector('.datepicker__wrapper');

  if (!datepickerInput || !datepickerLabel) {
    return;
  }

  let datepicker = new Datepicker('#datepicker', {
    yearRange: 100,
    onChange: (date) => {
      if (date instanceof Date) {
        datepickerLabel.classList.add('hidden');
      } else {
        datepickerLabel.classList.remove('hidden');
      }
    },
  });

  datepickerInput.addEventListener('input', (evt) => {
    if (evt.target.value !== '') {
      datepickerLabel.classList.add('hidden');
    } else {
      datepickerLabel.classList.remove('hidden');
    }
  });

  document.body.addEventListener('keydown', function (evt) {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      dateCalender.style.display = 'none';
    }
  });
};

export {initDate};
