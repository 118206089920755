import {disableBodyScroll, enableBodyScroll} from '../vendor/body-scroll-lock';

const toggleHeaderMenu = function () {
  let headerMenu = document.querySelector('[data-mobile-menu="header"]');
  let overlay = document.querySelector('.overlay');

  if (!headerMenu) {
    return;
  }

  const resizeHandler = () => {
    if (window.matchMedia('(min-width: 1280px)').matches) {
      enableBodyScroll(headerMenu);
    } else if (headerMenu.classList.contains('mobile-menu--open')) {
      disableBodyScroll(headerMenu);
    }
  };

  const openMenu = () => {
    headerMenu.classList.add('mobile-menu--open');
    disableBodyScroll(headerMenu);
    overlay.classList.add('overlay--show');
    window.addEventListener('resize', resizeHandler);
  };

  const closeMenu = () => {
    headerMenu.classList.remove('mobile-menu--open');
    enableBodyScroll(headerMenu);
    overlay.classList.remove('overlay--show');
    window.removeEventListener('resize', resizeHandler);
  };

  document.addEventListener('click', function (evt) {
    if (evt.target.closest('[data-header-nav-open]')) {
      openMenu();
    }

    if (evt.target.closest('[data-header-nav-close], .overlay')) {
      closeMenu();
    }
  });

  document.addEventListener('keydown', function (evt) {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      if (overlay.classList.contains('overlay--show') === true) {
        closeMenu();
      }
    }
  });
};

export {toggleHeaderMenu};
