/* globals Swiper */
const enableCertificatesSlider = () => {
  const certificates = new Swiper('.certificates__slider', {
    a11y: {
      notificationClass: 'certificates__notification',
      prevSlideMessage: 'Предыдущий слайд',
      nextSlideMessage: 'Следующий слайд',
      firstSlideMessage: 'Первый слайд',
      lastSlideMessage: 'Последний слайд',
    },
    navigation: {
      nextEl: '.certificates__button--next',
      prevEl: '.certificates__button--prev',
      disabledClass: 'certificates__button--disabled',
    },
    containerModifierClass: 'certificates__slider--',
    slideClass: 'certificates__slide',
    slideActiveClass: 'certificates__slide--active',
    slideNextClass: 'certificates__slide--next',
    slidePrevClass: 'certificates__slide--prev',
    wrapperClass: 'certificates__wrapper',
    breakpoints: {
      1024: {
        slidesPerView: 'auto',
        spaceBetween: 51,
      },
      768: {
        slidesPerView: 'auto',
        spaceBetween: 30,
      },
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
    },
    pagination: {
      el: '.certificates__pagination',
      clickable: true,
      bulletClass: 'certificates__bullet',
      bulletActiveClass: 'certificates__bullet--active',
      clickableClass: 'certificates__pagination--clickable',
      modifierClass: 'certificates__pagination--',
    },
  });

  return certificates;
};


export {
  enableCertificatesSlider
};
