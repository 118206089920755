function initialFormListener(form, callback) {
  if (!form || !callback) {
    return false;
  }

  const allInputs = Array.from(form.querySelectorAll('input, select'));

  if (!allInputs.length) {
    return false;
  }

  const inputInitialValues = allInputs.map((item) => item.value);

  const areAllFieldsInitial = () => {
    return allInputs.every((item, index) => item.value === inputInitialValues[index]);
  };

  form.addEventListener('input', () => {
    callback(areAllFieldsInitial());
  });

  return true;
}

export {initialFormListener};
