const openModal = (modal, callback, preventScrollLock) => {
  modal.classList.add('modal--active');

  if (callback) {
    callback();
  }

  if (!preventScrollLock) {
    window.disableBodyScroll(modal, {
      reserveScrollBarGap: true,
    });
  }
};

const closeModal = (modal, callback, preventScrollLock) => {
  modal.classList.remove('modal--active');

  if (callback) {
    callback();
  }

  if (!preventScrollLock) {
    setTimeout(() => {
      window.enableBodyScroll(modal);
    }, 300);
  }
};

const closeApplicationModal = (modal, callback) => {
  modal.classList.remove('modal--active');

  if (callback) {
    let success = document.querySelector('.modal--success');

    setModalListeners(success, false, false);
    openModal(success, false, false);

  }
  setTimeout(() => {
    window.enableBodyScroll(modal);
  }, 300);

};

const closeVerificationModal = (modal, callback) => {
  modal.classList.remove('modal--active');

  if (callback) {
    let success = document.querySelector('.modal--success');

    setModalListeners(success, false, false);
    openModal(success, false, false);

  }
  setTimeout(() => {
    window.enableBodyScroll(modal);
  }, 300);

};

const onEscPress = (evt, modal, callback) => {
  const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';

  if (isEscKey && modal.classList.contains('modal--active')) {
    evt.preventDefault();
    closeModal(modal, callback);
  }
};

const setModalListeners = (modal, closeCallback, preventScrollLock) => {
  const overlay = modal.querySelector('.modal__overlay');
  const closeBtn = modal.querySelector('.modal__close-btn');
  const closeCustomBtn = modal.querySelector('.modal__close-custom-btn');
  const applicationModalForm = document.querySelector('.modal--application form');
  const applicationModal = document.querySelector('.modal--application');
  const verificationModalForm = document.querySelector('.modal--verification form');
  const verificationModal = document.querySelector('.modal--verification');
  const withdrawalModalForm = document.querySelector('.modal--withdrawal form');
  const withdrawalModal = document.querySelector('.modal--withdrawal');
  const twoFAModal = document.querySelector('.modal--2fa');
  const profileModal = document.querySelector('.modal--profile');

  if (applicationModal) {
    applicationModalForm.addEventListener('submit', (evt) => {
      let valid = true;
      if (valid === true) {
        evt.preventDefault();
        closeApplicationModal(applicationModal, true, true);
      } else {
        evt.preventDefault();
      }
    });
  }

  if (verificationModal) {
    verificationModalForm.addEventListener('submit', (evt) => {
      let valid = true;
      if (valid === true) {
        evt.preventDefault();
        closeVerificationModal(verificationModal, true, true);
      } else {
        evt.preventDefault();
      }
    });
  }

  if (withdrawalModal) {
    withdrawalModalForm.addEventListener('submit', (evt) => {
      let valid = true;
      if (valid === true) {
        evt.preventDefault();
        closeVerificationModal(withdrawalModal, true, true);
      } else {
        evt.preventDefault();
      }
    });
  }

  if (twoFAModal) {
    const twoFAModalForm = document.querySelector('.modal--2fa');
    if (!twoFAModalForm) {
      return;
    }

    twoFAModalForm.addEventListener('submit', (evt) => {
      let valid = true;
      if (valid === true) {
        evt.preventDefault();
        closeVerificationModal(twoFAModal, true, true);
      } else {
        evt.preventDefault();
      }
    });
  }

  if (profileModal) {
    const withdrawButton = modal.querySelector('[data-modal="withdrawal"]');

    if (withdrawButton) {
      withdrawButton.addEventListener('click', () => {
        closeModal(profileModal);
      });
    }
  }

  if (closeCustomBtn) {
    closeCustomBtn.addEventListener('click', () => {
      closeModal(modal, closeCallback, preventScrollLock);
    });
  }
  closeBtn.addEventListener('click', () => {
    closeModal(modal, closeCallback, preventScrollLock);
  });

  overlay.addEventListener('click', () => {
    closeModal(modal, closeCallback, preventScrollLock);
  });

  document.addEventListener('keydown', (evt) => {
    onEscPress(evt, modal, closeCallback, preventScrollLock);
  });
};

const setupModal = (modal, closeCallback, modalBtn, openCallback, noPrevDefault, preventScrollLock) => {
  if (modalBtn) {
    modalBtn.addEventListener('click', (evt) => {
      if (!noPrevDefault) {
        evt.preventDefault();
      }
      openModal(modal, openCallback, preventScrollLock);
    });
  }

  setModalListeners(modal, closeCallback, preventScrollLock);
};

export {setupModal, openModal, closeModal};
