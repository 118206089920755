import {modalCodesCheck} from './modalCodesCheck';

export function checkModalCodeReg() {

  const modalCodesReg = document.querySelector('.modal--codes-reg');
  if (!modalCodesReg) {
    return;
  }
  const inputEmail = document.querySelector('#modal-codes-reg-email');
  const inputSMS = document.querySelector('#modal-codes-reg-sms');
  const time = document.querySelector('#modal-codes-reg-time');
  const messageLink = document.querySelector('#modal-codes-reg-message-link');

  modalCodesCheck(inputEmail, inputSMS, time, messageLink);
}
