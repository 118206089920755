const getToAnchor = function (event, anchor) {
  event.preventDefault();

  let blockID = anchor.getAttribute('href').substr(1);

  if (blockID !== '') {
    document.getElementById(blockID).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};

const setAnchors = function () {
  let anchors = document.querySelectorAll('a[href*="#"]');
  for (let i = 0; i < anchors.length; i++) {
    (function () {
      let anchor = anchors[i];
      anchor.addEventListener('click', function () {
        getToAnchor(event, anchor);
      });
    })();
  }
};

export {setAnchors};
