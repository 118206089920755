export function countdown(el, number = 0) {
  if (number === 0) {
    number = Number(el.textContent);
  }
  return new Promise((resolve) => {
    el.textContent = number;
    const timer = setInterval(() => {
      el.textContent = --number;
      if (number === 0) {
        clearInterval(timer);
        resolve();
      }
    }, 1000);
  });
}
