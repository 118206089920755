const manageFileUploaders = function () {
  const uploadBlocks = document.querySelectorAll('.upload-file');

  if (uploadBlocks) {
    uploadBlocks.forEach((uploadBlock) => {
      const loadFileInput = uploadBlock.querySelector('input[type="file"]');
      const loadFileButton = uploadBlock.querySelector('.upload-file__button');
      const loadDeleteFileButton = uploadBlock.querySelector('.upload-file__remove-file');
      const loadFileName = uploadBlock.querySelector('.upload-file__filename');

      if (loadFileInput && loadFileButton && loadDeleteFileButton && loadFileName) {
        loadFileInput.addEventListener('change', function () {
          const fileName = loadFileInput.value.replace(/\\/g, '/').split('/').pop();
          loadFileName.textContent = '' + fileName;

          if (loadFileInput.files[0].size > 4194304) {
            loadFileInput.value = '';
          }

          if (loadFileInput.value !== '') {
            loadDeleteFileButton.style.display = 'block';
            loadFileButton.classList.add('upload-file__button--loaded');
            loadFileInput.disabled = true;
            loadFileInput.blur();

          } else {
            loadFileName.textContent = 'Файл не выбран';
            loadDeleteFileButton.style.display = 'none';
            loadFileButton.classList.remove('upload-file__button--loaded');
          }
        });

        loadDeleteFileButton.addEventListener('click', function () {
          loadFileInput.value = '';
          loadFileName.textContent = 'Файл не выбран';
          loadDeleteFileButton.style.display = 'none';
          loadFileButton.classList.remove('upload-file__button--loaded');
          loadFileInput.disabled = false;
        });
      }
    });
  }
};

export {
  manageFileUploaders
};
