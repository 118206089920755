const enableLastExchangesScroll = () => {
  if (document.querySelector('.last-exchanges')) {
    let lastExchanges = document.querySelector('.last-exchanges');
    let lastExchangesClose = document.querySelector('.last-exchanges__close');
    let lastExchangesCoordinates = lastExchanges.getBoundingClientRect();
    let blockFromPoint;
    let parentElement;
    const blueColorBlocks = ['rgb(255, 255, 255)', 'rgb(235, 241, 247)'];
    let blockFromPointColor;
    let parentElementColor;
    if (!(window.getComputedStyle(lastExchanges, null).getPropertyValue('display') === 'none')) {
      const getBlockColors = () => {
        let blockPoint = document.elementFromPoint(lastExchangesCoordinates.x - 1, lastExchangesCoordinates.y);
        let elementParent = blockPoint.parentElement;
        blockFromPointColor = window.getComputedStyle(blockPoint, null).getPropertyValue('background-color');
        parentElementColor = window.getComputedStyle(elementParent, null).getPropertyValue('background-color');
      };
      getBlockColors();
    }

    const textIsBlue = (arr, val) => {
      return arr.some((arrVal) => val === arrVal);
    };

    if (textIsBlue(blueColorBlocks, parentElementColor) || textIsBlue(blueColorBlocks, blockFromPointColor)) {
      lastExchanges.classList.add('last-exchanges--blue');
      lastExchangesClose.classList.add('last-exchanges__close--blue');
    } else {
      lastExchanges.classList.remove('last-exchanges--blue');
      lastExchangesClose.classList.remove('last-exchanges__close--blue');
    }
    window.addEventListener('scroll', () => {
      if (!(window.getComputedStyle(lastExchanges, null).getPropertyValue('display') === 'none')) {
        blockFromPoint = document.elementFromPoint(lastExchangesCoordinates.x - 1, lastExchangesCoordinates.y);
        if (!blockFromPoint) {
          return;
        }

        parentElement = blockFromPoint.parentElement;
        blockFromPointColor = window.getComputedStyle(blockFromPoint, null).getPropertyValue('background-color');
        parentElementColor = window.getComputedStyle(parentElement, null).getPropertyValue('background-color');
        if (textIsBlue(blueColorBlocks, parentElementColor) || textIsBlue(blueColorBlocks, blockFromPointColor) || parentElementColor === 'rgb(46, 62, 92)') {
          lastExchanges.classList.add('last-exchanges--blue');
          lastExchangesClose.classList.add('last-exchanges__close--blue');
        } else {
          lastExchanges.classList.remove('last-exchanges--blue');
          lastExchangesClose.classList.remove('last-exchanges__close--blue');
        }
      }
    });
  }
};
export {
  enableLastExchangesScroll
};
