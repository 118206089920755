function currentDate() {

  function updateTime() {
    let dayOfWeek = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
    let date = new Date();
    let currentHours = date.getHours();
    let currentMinutes = date.getMinutes();
    let currentDay = date.getDay();
    let currentTime = document.querySelector('.working-hours__time');

    if (currentTime) {
      currentTime.textContent = `${currentHours < 10 ? '0' + currentHours : currentHours}:${currentMinutes < 10 ? '0' + currentMinutes : currentMinutes}, ${dayOfWeek[currentDay]}`;
    }
  }

  setInterval(updateTime, 60);
}

export {currentDate};
