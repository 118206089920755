/* globals Swiper */
const enableReservesSlider = () => {
  const certificates = new Swiper('.reserves__slider', {
    a11y: {
      notificationClass: 'reserves__notification',
      prevSlideMessage: 'Предыдущий слайд',
      nextSlideMessage: 'Следующий слайд',
      firstSlideMessage: 'Первый слайд',
      lastSlideMessage: 'Последний слайд',
    },
    navigation: {
      nextEl: '.reserves__button--next',
      prevEl: '.reserves__button--prev',
      disabledClass: 'reserves__button--disabled',
    },
    containerModifierClass: 'reserves__slider--',
    slideClass: 'reserves__slide',
    slideActiveClass: 'reserves__slide--active',
    slideNextClass: 'reserves__slide--next',
    slidePrevClass: 'reserves__slide--prev',
    wrapperClass: 'reserves__wrapper',
    breakpoints: {
      1024: {
        slidesPerView: 4,
        slidesPerGroup: 4,
        spaceBetween: 22,
      },
      768: {
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 15,
      },
      320: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 13,
      },
    },
  });

  return certificates;
};


export {
  enableReservesSlider
};
