const setCopyButton = function () {
  const copyButtons = document.querySelectorAll('[data-copy-from-id]');

  if (copyButtons) {
    copyButtons.forEach((copyButton) => {
      const copyFromId = copyButton.dataset.copyFromId;
      const copyFrom = document.querySelector('#' + copyFromId);
      const ACTIVE_BUTTON_CLASS = copyButton.dataset.activeClass ? copyButton.dataset.activeClass : 'button--blue-on-click';
      const changeButtonColor = function () {
        copyButton.classList.remove(ACTIVE_BUTTON_CLASS);
        copyButton.blur();
      };

      if (copyFrom) {
        copyButton.addEventListener('click', () => {
          copyButton.classList.add(ACTIVE_BUTTON_CLASS);
          setTimeout(changeButtonColor, 3000);
          navigator.clipboard.writeText(copyFrom.value ? copyFrom.value : copyFrom.textContent);
        });
      }
    });
  }
};

export {setCopyButton};
