function initBackToTop() {
  const SCREENS_SCROLLED_UNTIL_SHOW_BUTTON = 1;
  const DISAPPEAR_TRANISITION_TIME = 600;

  const backToTopButton = document.querySelector('[data-back-to-top]');
  let transitionTimeout;

  if (!backToTopButton) {
    return;
  }

  const showButton = function () {
    clearTimeout(transitionTimeout);
    backToTopButton.style.display = 'block';

    transitionTimeout = setTimeout(() => {
      backToTopButton.style.opacity = 1;
    }, 1);
  };

  const hideButton = function () {
    clearTimeout(transitionTimeout);
    backToTopButton.style.opacity = 0;

    transitionTimeout = setTimeout(() => {
      backToTopButton.style.display = 'none';
    }, DISAPPEAR_TRANISITION_TIME);
  };

  const footer = document.querySelector('.footer');

  const fixButtonToFooterPosition = function () {
    if (!footer) {
      return;
    }

    const MIN_VERTICAL_OFFSET = 0.1;
    const documentHeight = document.documentElement.offsetHeight;
    const heightToBottomOfThePage = documentHeight - window.scrollY - document.documentElement.clientHeight;
    const footerHeight = footer.getBoundingClientRect().height;
    const backToTopPosition = heightToBottomOfThePage + document.documentElement.clientHeight * MIN_VERTICAL_OFFSET;
    const footerPosition = footer.getBoundingClientRect().y;

    if (backToTopPosition < footerHeight) {
      backToTopButton.style.bottom = window.innerHeight - footerPosition + 'px';
    } else {
      backToTopButton.style.bottom = '';
    }
  };

  const showOrHideButton = function () {
    const showButtonOffset = document.documentElement.clientHeight * SCREENS_SCROLLED_UNTIL_SHOW_BUTTON;

    if (window.scrollY > showButtonOffset) {
      showButton();
    } else if (footer) {
      const isFooterOnScreen = footer.getBoundingClientRect().y < document.documentElement.clientHeight;

      if (window.scrollY > 0 && isFooterOnScreen) {
        showButton();
      } else {
        hideButton();
      }
    } else {
      hideButton();
    }
  };

  window.addEventListener('resize', fixButtonToFooterPosition);

  document.addEventListener('scroll', () => {
    showOrHideButton();
    fixButtonToFooterPosition();
  });

  showOrHideButton();
  fixButtonToFooterPosition();
}

export {initBackToTop};
