import {checkInput} from './check-input';

export function checkRegistration() {
  const registrationInput = document.querySelector('#modal-registration');
  const authorizationInput = document.querySelector('#modal-authorization');
  const registrationInputCode = document.querySelector('#modal-registration-two');
  const authorizationInputCode = document.querySelector('#modal-authorization-two');

  if (registrationInput) {
    registrationInput.addEventListener('input', checkInput);
  }
  if (authorizationInput) {
    authorizationInput.addEventListener('input', checkInput);
  }
  if (registrationInputCode) {
    registrationInputCode.addEventListener('input', checkInput);
  }

  if (authorizationInputCode) {
    authorizationInputCode.addEventListener('input', checkInput);
  }
}
