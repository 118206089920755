/* globals clamp */

let version;

const VERSIONS = {
  DESKTOP: 'Desktop',
  TABLET: 'Tablet',
  MOBILE: 'Mobile',
};

const VERSION_WIDTHS = {
  DESKTOP: 1024,
  TABLET: 768,
  MOBILE: 320,
};

const clampingText = {
  message: {
    selector: '.message__text',
    maxStrings: {
      'Desktop': 2,
      'Tablet': 2,
      'Mobile': 2,
    },
  },

  knowledgeCard: {
    selector: '.knowledge-card__text',
    maxStrings: {
      'Desktop': 4,
      'Tablet': 4,
      'Mobile': 4,
    },
  },

  fileName: {
    selector: '.upload-file__filename',
    maxStrings: {
      'Desktop': 3,
      'Tablet': 3,
      'Mobile': 2,
    },
  },
};

const getVersion = function () {
  switch (true) {
    case (document.documentElement.clientWidth >= VERSION_WIDTHS.DESKTOP):
      version = VERSIONS.DESKTOP;
      break;
    case (document.documentElement.clientWidth >= VERSION_WIDTHS.TABLET):
      version = VERSIONS.TABLET;
      break;
    case (document.documentElement.clientWidth >= VERSION_WIDTHS.MOBILE):
      version = VERSIONS.MOBILE;
      break;
  }
};

const clampText = function () {
  getVersion();

  for (let key in clampingText) {
    if (clampingText[key].selector !== null) {
      const elems = document.querySelectorAll(clampingText[key].selector);
      if (elems !== null) {
        elems.forEach((elem) => clamp(elem, {clamp: clampingText[key].maxStrings[version]}));
      }
    }
  }
};

export {clampText};
