function validationForm() {
  let mainAuthorization = document.querySelector('.authorization-main');

  if (mainAuthorization) {
    let inputsAuthorization = document.querySelectorAll('.authorization-main input');
    let sendButtonAuthorization = document.querySelector('.authorization-main button[type=submit]');
    let formAuthorization = document.querySelector('.authorization-main form');

    let verificationData = function (input) {
      let minCharacters = 8;
      let maxCharacters = 30;

      if (input.value.length >= minCharacters && input.value.length <= maxCharacters) {
        if (input.validity.patternMismatch) {
          input.setCustomValidity(input.dataset.textPatternMismatch);
          inputsAuthorization[1].setCustomValidity(inputsAuthorization[1].dataset.textPatternMismatch);
          input.classList.add('authorization-main__input-invalid');
        } else {
          input.classList.remove('authorization-main__input-invalid');
          input.setCustomValidity('');
        }
      } else {
        input.setCustomValidity(input.dataset.textMinmax);
        input.classList.add('authorization-main__input-invalid');
      }
    };

    let activateButton = function (evt) {
      if (!evt.target.value.length > 0) {
        sendButtonAuthorization.disabled = true;
        return;
      }
      sendButtonAuthorization.disabled = false;
    };

    let verificationFields = function () {
      for (let i = 0; i < inputsAuthorization.length; i++) {
        sendButtonAuthorization.addEventListener('click', function () {
          verificationData(inputsAuthorization[i]);
        });

        sendButtonAuthorization.disabled = true;

        formAuthorization.addEventListener('input', function (evt) {
          activateButton(evt);
        });
      }
    };

    if (formAuthorization && inputsAuthorization && sendButtonAuthorization) {
      verificationFields();
    }
  }
}

export {validationForm};
