function tabsSelect() {
  const ACTIVE_BUTTON_CLASS = 'advertisement__button--active';
  const ACTIVE_TAB_CLASS = 'advertisement__tab--active';
  const ACTIVE_NAV_CLASS = 'advertisement__nav--active';
  const ACTIVE_SELECT_CLASS = 'advertisement__active-tab-label--open';

  const tabButtons = document.querySelectorAll('[data-tab-id]');
  const tabs = document.querySelectorAll('[id|="tab"]');
  const tabsSelectLabel = document.querySelector('.advertisement__active-tab-label');
  const navMenu = document.querySelector('.advertisement__nav');

  if (tabButtons && tabs) {
    tabButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const selectedTab = document.querySelector('#' + button.dataset.tabId);

        if (selectedTab) {
          tabs.forEach((tab) => {
            tab.classList.remove(ACTIVE_TAB_CLASS);
          });

          tabButtons.forEach((tabButton) => {
            tabButton.classList.remove(ACTIVE_BUTTON_CLASS);
          });

          selectedTab.classList.add(ACTIVE_TAB_CLASS);
          button.classList.add(ACTIVE_BUTTON_CLASS);
        }
      });
    });
  }

  if (tabsSelectLabel && navMenu && tabButtons && tabs) {
    tabsSelectLabel.addEventListener('click', () => {
      navMenu.classList.toggle(ACTIVE_NAV_CLASS);
      tabsSelectLabel.classList.toggle(ACTIVE_SELECT_CLASS);
    });
    tabButtons.forEach((btn) => {
      btn.addEventListener('click', () => {
        navMenu.classList.remove(ACTIVE_NAV_CLASS);
        tabsSelectLabel.classList.remove(ACTIVE_SELECT_CLASS);
        tabsSelectLabel.textContent = btn.textContent;
      });
    });
  }
}

export {tabsSelect};
