/* globals Swiper */
const enableMainReviewsSlider = () => {
  const certificates = new Swiper('.main-reviews__slider', {
    a11y: {
      notificationClass: 'main-reviews__notification',
      prevSlideMessage: 'Предыдущий слайд',
      nextSlideMessage: 'Следующий слайд',
      firstSlideMessage: 'Первый слайд',
      lastSlideMessage: 'Последний слайд',
    },
    navigation: {
      nextEl: '.main-reviews__button--next',
      prevEl: '.main-reviews__button--prev',
      disabledClass: 'main-reviews__button--disabled',
    },
    containerModifierClass: 'main-reviews__slider--',
    slideClass: 'main-reviews__slide',
    slideActiveClass: 'main-reviews__slide--active',
    slideNextClass: 'main-reviews__slide--next',
    slidePrevClass: 'main-reviews__slide--prev',
    wrapperClass: 'main-reviews__wrapper',
    breakpoints: {
      768: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
      },
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 30,
      },
    },
  });

  return certificates;
};


export {
  enableMainReviewsSlider
};
